import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Language } from '../../enums/language.enum';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { TranslateConfigService } from '../../services/translate-config.service';

@Component({
  selector: 'app-language-selector-modal',
  templateUrl: './language-selector-modal.component.html',
  styleUrls: ['./language-selector-modal.component.scss'],
})
export class LanguageSelectorModalComponent implements OnInit, OnDestroy {
  @Input()
  public place: string;
  public environment = environment;
  public language = Language;
  public currentLanguage: Language;

  private _subscribers: Subscription[] = [];

  constructor(
    private _modalController: ModalController,
    private _translateConfigService: TranslateConfigService,
    private _unsubscribeHelper: UnsubscribeHelper,
    private _analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this._subscribers.push(
      this._translateConfigService.language.subscribe((lang) => {
        this.currentLanguage = lang;
      })
    );
    this._analyticsService.onLanguageSelectorOpen(this.place);
  }

  public ngOnDestroy(): void {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }

  public selectLanguage(lang: Language) {
    this._translateConfigService.setLanguage(lang, true);
    this.close();
  }

  public close(action: boolean = false): void {
    this._modalController.dismiss(action);
  }
}
