import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

export interface ToastProps {
  duration?: number;
  position?: 'bottom' | 'top';
  closeButton?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  private readonly _defaultDuration = 2000;
  private readonly _defaultPosition = 'bottom';

  constructor(
    private _toastController: ToastController,
    private _translateService: TranslateService
  ) {}

  public showMessage(message: string, props?: ToastProps): void {
    this.showToast(message, 'info', props);
  }

  public showError(error?: string, props?: ToastProps): void {
    this.showToast(
      error || this._translateService.instant('SHARED.ERROR_DEFAULT'),
      'error',
      props
    );
  }

  private showToast(
    message: string,
    type: 'info' | 'error',
    props?: ToastProps
  ) {
    let icon = '';
    switch (type) {
      case 'info':
        icon = 'information-circle-outline';
        break;
      case 'error':
        icon = 'alert-circle-outline';
        break;
    }

    this._toastController
      .create({
        message,
        duration: props?.duration ?? this._defaultDuration,
        position: props?.position ?? this._defaultPosition,
        cssClass: type,
        icon,
        buttons: props?.closeButton ? [{ icon: 'close-outline' }] : [],
      })
      .then((toast) => toast.present());
  }
}
