import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumUnsubscribeModalComponent } from './premium-unsubscribe-modal.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [PremiumUnsubscribeModalComponent],
  imports: [CommonModule, TranslateModule],
  exports: [PremiumUnsubscribeModalComponent],
})
export class PremiumUnsubscribeModalModule {}
