<ng-container>
  <div class="modal-container animate-bottom">
    <div class="modal modal-vertical modal-no-padding modal-auto-height">
      <button
        (click)="this.close()"
        [disabled]="pending"
        class="close-modal-btn"
      >
        <img [src]="environment.rootPath + '/assets/img/close-modal-btn.svg'" />
      </button>
      <div class="unsubscribe-modal-content">
        <h2 *ngIf="!isUnsubscribeSuccess">
          {{ "MODALS.PREMIUM_UNSUBSCRIBE.QUESTION" | translate }}
        </h2>

        <h2 *ngIf="isUnsubscribeSuccess">
          {{ "MODALS.PREMIUM_UNSUBSCRIBE.SUCCESS" | translate }}
        </h2>

        <div *ngIf="isUnsubscribeSuccess" class="btns">
          <button (click)="this.close()" class="btn btn-block btn-green">
            {{ "SHARED.CLOSE" | translate }}
          </button>
        </div>

        <div *ngIf="!isUnsubscribeSuccess" class="btns">
          <button
            (click)="this.close()"
            [disabled]="pending"
            [class.spinner]="pending"
            class="btn btn-block btn-green"
          >
            {{ "SHARED.CANCEL" | translate }}
          </button>
          <button
            (click)="unsubscribe()"
            [disabled]="pending"
            [class.spinner]="pending"
            class="btn btn-block btn-grey"
          >
            {{ "MODALS.PREMIUM_UNSUBSCRIBE.YES" | translate }}
          </button>
        </div>

        <p *ngIf="!isUnsubscribeSuccess">
          {{ "MODALS.PREMIUM_UNSUBSCRIBE.CANCEL_INFO" | translate }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
