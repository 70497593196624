import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { BannerSliderComponent } from './banner-slider.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [BannerSliderComponent],
  imports: [CommonModule, SwiperModule, DirectivesModule, PipesModule, TranslateModule],
  exports: [BannerSliderComponent],
})
export class BannerSliderModule {}
