<div class="payment-loader" *ngIf="state === paymentAnimationStates.progress">
  <ng-lottie
    class="lottie-animation"
    [options]="paymentInProgressOptions"
  ></ng-lottie>
</div>
<div class="payment-success" *ngIf="state === paymentAnimationStates.success">
  <ng-lottie
    class="lottie-animation"
    [options]="paymentIsSuccessOptions"
  ></ng-lottie>
</div>
<div class="payment-error" *ngIf="state === paymentAnimationStates.error">
  <ng-lottie
    class="lottie-animation"
    [options]="paymentIsErrorOptions"
  ></ng-lottie>
</div>
