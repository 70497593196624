import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscordLinkComponent } from './discord-link.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DiscordLinkComponent],
  imports: [CommonModule, TranslateModule],
  exports: [DiscordLinkComponent],
})
export class DiscordLinkModule {}
