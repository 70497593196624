import { PromoBrand } from '../enums/chat-promo-brand.enum';

export enum GameSections {
  singleChat = 'Single Chat',
  chatList = 'Chat List',
  profile = 'Profile',
  cards = 'Cards',
  shop = 'Shop',
}

export interface PromoAnalyticsData {
  promo: PromoBrand;
  section: GameSections;
  placeInSection?: string;
  chatName?: string;
  messageIndex?: number;
  positionInChatList?: number;
  bannerSlide?: string;
  link?: any;
}
