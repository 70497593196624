<ng-container>
  <div class="modal">
    <div class="modal-head">
      {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.WARNING" | translate }}
    </div>
    <div class="modal-body">
      <div
        class="confirm-text"
        *ngIf="environment.buildVersion === buildVersion.erogames"
      >
        {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.EROGAMES" | translate }}
      </div>
      <div
        class="confirm-text"
        *ngIf="environment.buildVersion === buildVersion.nutaku"
      >
        {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.NUTAKU" | translate }}
      </div>

      <div class="buttons">
        <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >
          {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.NOT_NOW" | translate }}
        </button>

        <button
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
        >
          <span *ngIf="environment.buildVersion === buildVersion.erogames">
            {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.BUY_EROGAMES" | translate }}
          </span>
          <span *ngIf="environment.buildVersion === buildVersion.nutaku">
            {{ "MODALS.PAYMENTS.NOT_ENOUGH_MONEY.BUY_NUTAKU" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
