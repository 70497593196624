<div class="loader-box" [class.background]="isAddBackground">
  <div class="loader-wrapper">
    <div class="loader">
      <div class="roller"></div>
      <div class="roller"></div>
    </div>
    <div id="loader2" class="loader">
      <div class="roller"></div>
      <div class="roller"></div>
    </div>
    <div id="loader3" class="loader">
      <div class="roller"></div>
      <div class="roller"></div>
    </div>
  </div>
</div>
