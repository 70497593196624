<div class="content el2 active">
  <form [formGroup]="form">
    <ng-container *ngIf="step === 1">
      <p class="lead-text">
        {{ "MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.ENTER_EMAIL" | translate }}
      </p>
      <div *ngFor="let formItem of [formArray[0]]">
        <app-field
          [errors]="
            registrationHelperService.getErrors(form, formItem.controlName)
          "
          [className]="formItem.className"
        >
          <input
            [type]="formItem.fieldType"
            class="form-control"
            [placeholder]="formItem.placeHolder"
            [formControlName]="formItem.controlName"
            [class.error]="
              registrationHelperService.isErrorClass(form, formItem.controlName)
            "
          />
        </app-field>

        <div class="form-offset">
          <button
            [class.spinner]="forgotLoading"
            [disabled]="forgotLoading"
            [ngClass]="{
              disabled: form.controls[formItem.controlName].invalid
            }"
            class="btn btn-primary btn-block btn-uppercase"
            type="submit"
            (click)="getCode(form.controls[formItem.controlName].invalid)"
          >
            {{ "MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.GET_CODE" | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="step === 2">
      <app-field
        *ngFor="let formItem of [formArray[1], formArray[2]]"
        [errors]="
          registrationHelperService.getErrors(form, formItem.controlName)
        "
        [className]="formItem.className"
      >
        <input
          [type]="formItem.fieldType"
          class="form-control"
          [placeholder]="formItem.placeHolder"
          [formControlName]="formItem.controlName"
          [class.error]="
            registrationHelperService.isErrorClass(form, formItem.controlName)
          "
        />
      </app-field>
      <div class="form-offset display-flex-buttons">
        <button
          [disabled]="loading"
          class="btn btn-grey btn-block btn-uppercase"
          type="submit"
          (click)="changeStep('prev')"
        >
          {{ "MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.BACK" | translate }}
        </button>
        <button
          [class.spinner]="loading"
          [disabled]="loading"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="resetPassword()"
        >
          {{ "MODALS.LOGIN.FORGOT_PASSWORD_BLOCK.SIGN_IN" | translate }}
        </button>
      </div>
    </div>

    <ng-container *ngIf="commonServerErrors">
      <div
        class="validation-error-block"
        *ngFor="let err of commonServerErrors"
      >
        {{ err }}
      </div>
    </ng-container>
  </form>
</div>
