import { Injectable } from '@angular/core';
import { AnimationController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ModalAnimationsService {
  constructor(private _animationController: AnimationController) {}

  public enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = this._animationController
      .create()
      .addElement(root.querySelector('ion-backdrop'))
      .fromTo('opacity', 0, 0.7);

    const backdropTransformAnimation = this._animationController
      .create()
      .addElement(root.querySelector('ion-backdrop'))
      .fromTo('transform', 'scale(1.1)', 'scale(1.1)');

    const wrapperAnimation = this._animationController
      .create()
      .addElement(root.querySelector('.modal-wrapper'))
      .fromTo('transform', 'translateY(5%)', 'translateY(0)');

    const wrapperOpacityAnimation = this._animationController
      .create()
      .addElement(root.querySelector('.modal-wrapper'))
      .fromTo('opacity', 0, 1);

    return this._animationController
      .create()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(200)
      .addAnimation([
        backdropAnimation,
        wrapperAnimation,
        wrapperOpacityAnimation,
        backdropTransformAnimation,
      ]);
  };

  public leaveAnimation = (baseEl: HTMLElement) =>
    this.enterAnimation(baseEl).direction('reverse');
}
