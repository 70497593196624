<div [className]="className" class="field-block">
  <ng-content></ng-content>

  <div
    class="error-message text-error validation-error-block"
    *ngIf="getErrors"
  >
    <div *ngFor="let err of getErrors">
      <div [ngSwitch]="err">
        <div *ngSwitchCase="'required'">
          {{ "MODALS.LOGIN.ERRORS.REQUIRED" | translate }}
        </div>
        <div *ngSwitchCase="'email'">
          {{ "MODALS.LOGIN.ERRORS.EMAIL" | translate }}
        </div>
        <div *ngSwitchCase="'incorrectCode'">
          {{ "MODALS.LOGIN.ERRORS.INCORRECT_CODE" | translate }}
        </div>
        <div *ngSwitchDefault>
          {{ err }}
        </div>
      </div>
    </div>
  </div>
</div>
