<ng-container>
  <div class="modal-container animate-bottom">
    <div class="modal modal-vertical modal-no-padding modal-auto-height">
      <button (click)="this.close()" class="close-modal-btn">
        <img [src]="environment.rootPath + '/assets/img/close-modal-btn.svg'" />
      </button>
      <div class="language-modal-content">
        <h2 class="title">{{ "MODALS.LANGUAGE.TITLE" | translate }}</h2>
        <button
          (click)="selectLanguage(language.english)"
          class="lang-button"
          [class.selected]="currentLanguage === language.english"
        >
          <img
            [src]="environment.rootPath + '/assets/img/flags/en.png'"
            alt="en"
            class="flag"
          />
          {{ "COMMON.LANGUAGE.EN" | translate }}
        </button>

        <button
          (click)="selectLanguage(language.german)"
          class="lang-button"
          [class.selected]="currentLanguage === language.german"
        >
          <img
            [src]="environment.rootPath + '/assets/img/flags/de.svg'"
            alt="de"
            class="flag"
          />
          {{ "COMMON.LANGUAGE.DE" | translate }}
        </button>
        <!-- <button
          (click)="selectLanguage(language.french)"
          class="lang-button"
          [class.selected]="currentLanguage === language.french"
        >
          <img
            [src]="environment.rootPath + '/assets/img/flags/fr.png'"
            alt="fr"
            class="flag"
          />
          {{ "COMMON.LANGUAGE.FR" | translate }}
        </button>
        <button
          (click)="selectLanguage(language.spanish)"
          class="lang-button"
          [class.selected]="currentLanguage === language.spanish"
        >
          <img
            [src]="environment.rootPath + '/assets/img/flags/es.png'"
            alt="es"
            class="flag"
          />
          {{ "COMMON.LANGUAGE.ES" | translate }}
        </button> -->
      </div>
    </div>
  </div>
</ng-container>
