import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, take } from 'rxjs';
import { LanguageSelectorModalComponent } from '../components/language-selector-modal/language-selector-modal.component';
import { Language } from '../enums/language.enum';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';
import { AnalyticsService } from './analytics/analytics.service';
import { ModalsService } from './modals.service';
import { WebsocketSignalRService } from './websocket-signalr.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  public readonly availiableLanguages: Language[] = Object.values(Language);
  public language: BehaviorSubject<Language> = new BehaviorSubject(null);

  constructor(
    private _translate: TranslateService,
    private _analyticsService: AnalyticsService,
    private _modalsService: ModalsService,
    private _websocketSignalRService: WebsocketSignalRService
  ) {
    this._translate.setDefaultLang(Language.english);
  }

  public init(lang: Language | null): void {
    this._translate.setDefaultLang(Language.english);

    // // TODO wait for api fix
    // this.setLanguage(Language.english);
    // return;

    if (lang) {
      this.setLanguage(lang, false);
      return;
    }

    lang = localStorage.getItem('lang') as Language;
    if (lang) {
      this.setLanguage(lang, false);
      return;
    }

    const browserLang = this._translate.getBrowserLang() as Language;

    if (
      browserLang !== Language.english &&
      this.availiableLanguages.includes(browserLang)
    ) {
      this._modalsService
        .openModal(LanguageSelectorModalComponent, {
          place: 'startup',
        })
        .pipe(take(1))
        .subscribe();
    } else {
      this.setLanguage(Language.english);
    }
  }

  public setLanguage(
    lang: Language,
    sendAnalytics = true,
    setByUser = false
  ): void {
    this._translate.use(lang);
    this.language.next(lang);
    localStorage.setItem('lang', lang);

    this._websocketSignalRService
      .invoke(WebsocketCommandType.setLanguage, { lang })
      .pipe(take(1))
      .subscribe();

    if (sendAnalytics) {
      this._analyticsService.onSetLanguage(lang, setByUser);
    }
  }
}
