<div class="toast-body" [@slideInOutTopDir] *ngIf="data">
  <a
    class="toast-link"
    [href]="data.link"
    target="_blank"
    (click)="clickOnOnlineStream()"
  >
    <img src="/assets/img/bonus-page/quest-line/stripchat.svg" alt="" />
    {{ data.name }} is online on {{ data.platform }}
  </a>
  <div class="toast-buttons">
    <a class="toast-close" (click)="closeModal()">×</a>
    <a class="toast-edit" (click)="editNotification()"
      ><img src="/assets/img/notification-off.png" alt=""
    /></a>
  </div>
</div>
