<ng-container>
  <div class="modal">
    <div class="modal-head">{{"SHARED.WARNING" | translate}}</div>
    <div class="modal-body">
      <div class="confirm-text text-center">
        {{"SHARED.MAINTENANCE_WORK_TEXT" | translate}}
      </div>

      <div class="buttons">
        <button
          target="_blank"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="close()"
        >
          {{ "SHARED.CLOSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
