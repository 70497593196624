export enum UiStates {
  gallery = 'gallery',
  main = 'main',
  chat = 'chat',
  album = 'album',
  settings = 'settings',
  bonus = 'bonus',
  questLines = 'questLines',
  promoCode = 'promoCode',
  shop = 'shop',
  profile = 'profile',
  swipe = 'swipe',
}
