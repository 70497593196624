import { PaymentsCommonMethodsService } from './../../../services/payments/payments-common-methods.service';
import { PaymentResponse } from './../../../types/payment-response.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentsStateService } from 'src/app/shared/services/payments/payments-state.service';
import { WebPaymentService } from 'src/app/shared/services/payments/web-payment.service';

@Component({
  selector: 'app-payment-iframe',
  templateUrl: './payment-iframe.component.html',
  styleUrls: ['./payment-iframe.component.scss'],
})
export class PaymentIframeComponent implements OnInit, OnDestroy {
  showPaymentIframe = false;
  isAllowPaymentIframe = environment.allowPaymentIframe;
  paymentUrl: SafeUrl;
  purchaseSub: Subscription;
  purchasePackSub: Subscription;

  constructor(
    protected _sanitizer: DomSanitizer,
    private _paymentsStateService: PaymentsStateService,
    private _webPaymentService: WebPaymentService,
    private _paymentsCommonMethodsService: PaymentsCommonMethodsService
  ) {}

  ngOnInit() {
    this.purchaseSub = this._webPaymentService.purchaseViaIframeAction
      .pipe(filter((id) => id !== null))
      .subscribe((res: PaymentResponse) => {
        this.openInIframe(res);
      });
  }

  private openInIframe(res: PaymentResponse) {
    this.paymentUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      res.payment.url
    );
    this.showPaymentIframe = true;
  }

  closePaymentsIframe() {
    this._paymentsStateService.isPurchased('chatAll');
    this.showPaymentIframe = false;
    this._paymentsCommonMethodsService.checkTransaction();
  }

  ngOnDestroy() {
    this.purchaseSub.unsubscribe();
    this.purchasePackSub.unsubscribe();
  }
}
