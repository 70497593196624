export enum AuthPayload {
  clickId = 'clickId',
  partnerId = 'partnerId',
  trafficSource = 'trafficSource',
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmContent = 'utm_content',
  utmTerm = 'utm_term',
  landingBehavior = 'landing_behavior',
  forcedTest = 'forcedTest',
  caduid = 'caduid',
  tscid = 'tscid',
  gclickid = 'gclickid',
  uspidntfr = 'uspidntfr',
  usptoken = 'usptoken',
  uem = 'uem', // User Email
  tta = 'tta', // User token for auth form email
  ofi = 'ofi' // offerId
}
