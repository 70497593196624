<div class="discord-link">
  <a
    class="promo-click-area"
    [href]="environment.discordLink"
    target="_blank"
    [innerHTML]="'CHATS_PAGE.CLICK_HERE' | translate"
  ></a>

  <p class="main-information-description-10">
    Share you feedback and get cool gifts in our Discord
  </p>

  <img src="/assets/img/quests-page/icons/discord.png" alt="" />
</div>
