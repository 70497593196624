export enum SignMethod {
  email = 'email',
  google = 'google',
  twitter = 'twitter',
  erogames = 'erogames',
  discord = 'discord',
  asGuest = 'asGuest',
  nutaku = 'nutaku',
  authByPayment = 'authByPayment',
}
