export enum PaymentResponeStatus {
  completed = 'Completed',
  success = 'success',
  redirect = 'Redirect',
  error = 'Error',
  cancelled = 'Cancelled', // For NUTAKU
  issued = 'Issued', // For Nutaku
  insufficientFunds = 'InsufficientFunds',
  unauthorized = 'Unauthorized',
  notFound = 'NotFound'
}
