import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoader: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  get isLoader(): Observable<boolean> {
    return this._isLoader.asObservable();
  }

  public changeLoaderVisibility(state: boolean): void {
    this._isLoader.next(state);
  }
}
