<!-- <div class="description-text">
  Or sign in with Google and save your progress on cloud
</div> -->

<ng-container *ngIf="isGoogleAuthAllow">
  <div class="description-text">
    {{ "MODALS.LOGIN.SOCIALS.GOOGLE_SIGN_IN_DESCRIPTION" | translate }}
  </div>

  <div class="form-group" *ngIf="_platform.is('android')">
    <button
      [class.spinner]="loadingAndroidGoogle"
      [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
      type="button"
      (click)="loginAndroidWithGoogle()"
      class="btn btn-block btn-google"
    >
      <ng-container *ngIf="place === 'signup'">
        {{ "MODALS.LOGIN.SOCIALS.GOOGLE_SIGN_UP" | translate }}
      </ng-container>
      <ng-container *ngIf="place === 'login'">
        {{ "MODALS.LOGIN.SOCIALS.GOOGLE_LOGIN" | translate }}
      </ng-container>
    </button>
    <div *ngIf="googleErrorTxt" class="validation-error-block">
      {{ "MODALS.LOGIN.SOCIALS.ERROR" | translate }}
    </div>
  </div>

  <div class="form-group" *ngIf="!_platform.is('android')">
    <button
      [class.spinner]="loadingGoogle"
      [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
      type="button"
      (click)="loginWithGoogle()"
      class="btn btn-block btn-google"
    >
      <ng-container *ngIf="place === 'signup'">
        {{ "MODALS.LOGIN.SOCIALS.GOOGLE_SIGN_UP" | translate }}
      </ng-container>
      <ng-container *ngIf="place === 'login'">
        {{ "MODALS.LOGIN.SOCIALS.GOOGLE_LOGIN" | translate }}
      </ng-container>
    </button>
    <div *ngIf="googleErrorTxt" class="validation-error-block">
      {{ "MODALS.LOGIN.SOCIALS.ERROR" | translate }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isAppleAuthAllow">
  <div class="description-text">
    {{ "MODALS.LOGIN.SOCIALS.APPLE_SIGN_IN_DESCRIPTION" | translate }}
  </div>

  <div class="form-group">
    <button
      [class.spinner]="loadingApple"
      [disabled]="
        loadingGuest || loadingGoogle || loadingAndroidGoogle || loadingApple
      "
      type="button"
      (click)="loginWithApple()"
      class="btn btn-block btn-apple"
    >
      <ng-container *ngIf="place === 'signup'">
        {{ "MODALS.LOGIN.SOCIALS.APPLE_SIGN_IN" | translate }}
      </ng-container>
      <ng-container *ngIf="place === 'login'">
        {{ "MODALS.LOGIN.SOCIALS.APPLE_SIGN_IN" | translate }}
      </ng-container>
    </button>
    <div *ngIf="appleErrorTxt" class="validation-error-block">
      {{ "MODALS.LOGIN.SOCIALS.ERROR" | translate }}
    </div>
  </div>
</ng-container>

<!-- <div class="form-group">
  <button
    [class.spinner]="loadingTwitter"
    [disabled]="
      loadingGuest || loadingGoogle || loadingAndroidGoogle || loadingTwitter
    "
    type="button"
    (click)="goToTwitter()"
    class="btn btn-block btn-twitter"
  >
    <ng-container *ngIf="place === 'signup'">Sign up with Twitter</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Twitter</ng-container>
  </button>
  <div *ngIf="twitterErrorTxt" class="validation-error-block">
    Something went wrong.
  </div>
</div> -->

<!-- <div class="form-group">
  <button
    type="button"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
    (click)="goToDiscord()"
    class="btn btn-block btn-discord"
  >
    <ng-container *ngIf="place === 'signup'">Sign up with Discord</ng-container>
    <ng-container *ngIf="place === 'login'">Login with Discord</ng-container>
  </button>
  <div *ngIf="discordErrorTxt" class="validation-error-block">
    Something went wrong.
  </div>
</div> -->

<!-- <div class="form-offset">
  <button
    [class.spinner]="loadingGuest"
    [disabled]="loadingGuest || loadingGoogle || loadingAndroidGoogle"
    class="btn btn-grey btn-block btn-uppercase"
    (click)="guestEntry()"
    >
    Stay as guest
  </button>
</div> -->
