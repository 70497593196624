import { trigger, animate, transition, style } from '@angular/animations';

export const slideInOutBottomDir = trigger('slideInOutBottomDir', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: '0' }),
    animate(
      '200ms ease-in',
      style({ transform: 'translateY(0%)', opacity: '1' })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms ease-in',
      style({ transform: 'translateY(100%)', opacity: '0' })
    ),
  ]),
]);

export const slideInOutTopDir = trigger('slideInOutTopDir', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: '0' }),
    animate(
      '200ms ease-in',
      style({ transform: 'translateY(0%)', opacity: '1' })
    ),
  ]),
  transition(':leave', [
    animate(
      '200ms ease-in',
      style({ transform: 'translateY(-100%)', opacity: '0' })
    ),
  ]),
]);
