<img
  *ngIf="isErogames"
  src="/assets/img/erogold.svg"
  draggable="false"
  class="coin-icon"
/>
<img
  *ngIf="isNutaku"
  src="/assets/img/nutaku-gold-small.png"
  draggable="false"
  class="coin-icon"
/>

<ng-container *ngIf="price">{{ price }}</ng-container>
<span class="spinner spinner-inline" *ngIf="!price"></span>
