import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceBlockComponent } from './price-block.component';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [PriceBlockComponent],
  imports: [CommonModule, PipesModule],
  exports: [PriceBlockComponent],
})
export class PriceBlockModule {}
