import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PaymentAnimationStates } from '../../enums/payment-animation-states';
import { PaymentsStateService } from '../../services/payments/payments-state.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  paymentState: PaymentAnimationStates = null;
  paymentAnimationStates = PaymentAnimationStates;
  private _paymentsServiceSub: Subscription;
  constructor(private _paymentsStateService: PaymentsStateService) {}

  ngOnInit() {
    this._paymentsServiceSub =
      this._paymentsStateService.paymentState$.subscribe(
        (res: PaymentAnimationStates) => {
          this.paymentState = res;
        }
      );
  }

  ngOnDestroy() {
    this._paymentsServiceSub.unsubscribe();
  }
}
