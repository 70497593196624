export enum ChatMessageType {
  model = 'Model',
  hero = 'Hero',
  offline = 'Offline',
  advertisement = 'Advertisement',
  chooseAnswer = 'ChooseAnswer',
  modelVideo = 'ModelVideo',
  modelImage = 'ModelImage',
  modelAudio = 'ModelAudio',
}
