import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-discord-link',
  templateUrl: './discord-link.component.html',
  styleUrls: ['./discord-link.component.scss'],
})
export class DiscordLinkComponent {
  public readonly environment = environment;
}
