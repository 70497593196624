import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmobLoaderComponent } from './admob-loader.component';

@NgModule({
  declarations: [AdmobLoaderComponent],
  imports: [CommonModule],
  exports: [AdmobLoaderComponent],
})
export class AdmobLoaderModule {}
