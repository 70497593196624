import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NextMessageEmitterService {
  public getNextMessage = new Subject();

  public isShowTyping = new Subject();

  constructor() {
    this.getNextMessage.next(null);
    this.isShowTyping.next(false);
  }

  public changeData(state): void {
    this.getNextMessage.next(state);
  }

  public changeDataTyping(state: boolean): void {
    this.isShowTyping.next(state);
  }
}
