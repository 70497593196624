const settings = {
  gameServerUrl: '',
  domainName: '',
  isGuest: '',
  messageAwait: 0,
  isCanGetNewMessage: true,
  platform: 'ios', //'ios', 'android', 'web',
  quickMessaging: false,
  onlineStreamNotifications: true,
};

export default settings;
