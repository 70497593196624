<ng-container>
  <div class="modal-container animate-bottom">
    <div class="modal">
      <div class="modal-head modal-head--light"  [innerHTML]="
      'SHARED.HOW_SHOULD_WE_NAME_YOU' | translate">
      </div>
      <div class="modal-body">
        <ion-input
          #searchElement
          type="text"
          class="cozy-input form-control"
          [(ngModel)]="nickName"
          (keyup.enter)="saveName()"
        ></ion-input>
        <button class="btn btn-primary" (click)="saveName()">{{ "SHARED.SAVE" | translate}}</button>
      </div>
    </div>
  </div>
</ng-container>
